#footer {
  color: #fff;
  flex-flow: column wrap;
  justify-content: center;
  margin: 5em;
  display: flex;
}

#footer .footer-row {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

#footer a {
  color: #fff;
  text-decoration: none;
}

#footer a:hover {
  text-decoration: underline;
}

#footer section {
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  padding-left: 5em;
  padding-right: 5em;
  display: flex;
}

#footer section ul {
  margin: 0;
  padding: 0;
}

#footer h3 {
  color: #fff;
  margin-bottom: 0;
}

#copyright {
  text-align: center;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0;
}

#footer ul.social {
  flex-direction: row;
  font-size: 2em;
  display: flex;
}

#footer ul.social li {
  padding: .3em;
}

@media (orientation: portrait) {
  #footer .footer-row {
    flex-direction: column;
  }

  #footer {
    justify-content: flex-start;
  }

  #copyright {
    width: auto;
  }

  #footer section {
    padding-left: 0;
    padding-right: 0;
  }
}

/*# sourceMappingURL=footer.css.map */
