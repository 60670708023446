#footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin: 5em;
    color: white;
}

#footer .footer-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#footer a {
    color: white;
    text-decoration: none;
}
#footer a:hover {
    text-decoration: underline;
}

#footer section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;
    padding-left: 5em;
    padding-right: 5em;
}

#footer section ul {
    margin: 0;
    padding: 0;
}

#footer h3 {
    color: white;
    margin-bottom: 0;;
}

#copyright {
    margin: 0;
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
}

#footer ul.social {
    display: flex;
    flex-direction: row;
    font-size: 2em;
}
#footer ul.social li {
    padding: 0.3em;
}

@media (orientation: portrait){
    #footer .footer-row {
        flex-direction: column;
    }
    #footer {
        justify-content: flex-start;
    }
    #copyright {
        width: auto;
    }
    #footer section {
        padding-left: 0;
        padding-right: 0;
    }
}